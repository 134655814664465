.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.money_hit{
  text-align: center;
    width: 100%;
    display: block;
    background-color: #13263c;
    color: white;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.video_list
{
  height: 6rem;
}
.log_layer{
  position:fixed;
  width: 100%;
  height: 100vh;
  background-color: #000000a8;
  z-index: 10000;
  top: 0;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.log_box{
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(146, 208, 233);
  width: 35rem;
  height: 28rem;
  border-radius: 2rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.658);
  background-color: white;
  background: linear-gradient(121deg, rgba(124, 254, 246, 0.80) 15.52%, rgba(238, 168, 255, 0.80) 100%);
}
.log_close{
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.close_btn{
  height: 3rem;
  width: 5rem;
  background-image: url('/src/res/close-circle-fill.svg');
  background-repeat: no-repeat;
  background-position: center;    
  cursor: pointer;
}

.log_title{
  height: 4rem;
  color:black;
  font-size: 25px;
  display: flex;
  align-items:center;
  font-weight: bold;
  padding-left: 2rem;
  letter-spacing: .1rem;
}
.log_title span{
  font-size: 14px;
  color: #000000;
  margin-left: 1rem;
}
.input_box{
  height: 18rem;
  display: flex;
  flex-direction: column;
}
.input_box_inputdiv{
  width: 90%;
  height: 3.5rem;
  margin: 1rem auto;
  display: flex;
  flex-direction: row;
  border-radius: .5rem;
  background-color: #eeeeee;
  overflow: hidden;
}
.mobile_input{
  width: 100%;
  letter-spacing:.2rem;
  font-size: 15px;
  font-weight: bold;
  border: 0px;
  background-color: #eeeeee;
}
.mobile_svg{
  background-image: url('/src/res/mobile.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 3rem;
  height: 100%;
}
.ran_svg{
  background-image: url('/src/res/ran.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 3rem;
  height: 100%;
}
.get_rand{
  width: 6rem;
  display: flex;
  background: linear-gradient(138deg, #CDFF66 0%, #8EFFFE 100%);
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  cursor: pointer;
  letter-spacing: .5rem;
  font-weight: bold;
}
.login_btn{
font-size: 18px;
font-weight: bold;
color: black;
display: flex;
height: 3.2rem;
width: 26.25rem;
padding: 0.625rem 0rem 0.6875rem 0rem;
justify-content: center;
align-items: center;
border-radius: 8px;
background: linear-gradient(138deg, #CDFF66 0%, #8EFFFE 100%);
box-shadow: 0px 24px 32px 0px rgba(151, 255, 235, 0.20);
margin: auto;
cursor: pointer;
}
.btn_panel{
width: 18rem;
height: 3rem;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}
input{
  border: 0px;
  outline: none;
}
.wxpay_panel{
  position:fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #000000d2;
  z-index: 1000;
}
.wxpay_box{
  width: 25rem;
  height: 25rem;
  border: 1px solid rgb(63, 63, 63);
  position:absolute;
  background-color:rgba(17, 17, 17, 0.74);
  backdrop-filter: blur(5px);
  border-radius: .5rem;
  display: flex;
  flex-direction: column;
}
.wx_logo{
  background-image: url('/src/res/logo.svg');
  width: 100%;
  height: 4rem;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid rgb(46, 46, 46);

}
.wx_qr_box{
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem;
}
.qr_left{
  flex:0 0 20rem;
  display: flex;
  flex-direction: column;
  border-right: 1px solid rgba(68, 68, 68, 0.199);
  color: white;
  line-height: 3rem;
}

.qr_right{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.qr_right img{
  width: 14rem;
  margin: .5rem auto;
  border-radius: 1rem;
}
.pay_money{
  text-align: center;
  color: #26ad21;
  font-size: 20px;
}
.pay_money_title{
  text-align: center;
  color: #757575;
  font-size: 14px;
}
.qrcode{
  width: 12rem;
  height: 12rem;
  padding: .3rem;
  margin: 1rem auto;
  border: 1px solid rgb(75, 75, 75);
  border-radius: .5rem;
}
.fl_right{
  float: right;
}
@keyframes payok_box {  
  0% {  
    height: 0%;
  }  
  100% {  
    height:86%;
  }  
}
.pay_ok{
  color: #26ad21;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.658);
  margin: -1rem;
  display: flex;
  backdrop-filter: blur(5px);
  animation:  payok_box 0.15s linear forwards;  
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ok_img{
  background-image: url('/src/res/success.svg');
  width: 5rem;
  height: 5rem;
  background-repeat: no-repeat;
  background-position: center;

}
.ckbox{
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
  height: 3rem;
  display:flex;
  align-items: center;
  letter-spacing: .2rem;
  cursor: pointer;
}
.ckbox input{
  margin-right: .5rem;
  width: 19px;
  height: 19px;
}
.ckbox a{
  color: #e6740b;
}